<template>
  <div>
    <div class="flex flex-col gap-8">
      <div>
        <h3 class="font-medium text-xl truncate w-11/12">
          {{ actionPlan?.agenda }}
        </h3>
        <div class="py-8">
          <Tabs
            :key="tabsData"
            :tabsData="tabsData"
            :activeTabIndex="activeTabIndex"
            @index="switchTab"
          />
        </div>
        <div :key="tabsIndex" v-if="tabsIndex === 0">
          <ActionPlanComponent :actionPlan="actionPlan" :mode="'view'" />
        </div>
        <div
          v-for="(meeting, index) in actionPlan?.action_plan_meetings"
          :key="index"
        >
          <ActionPlanMeeting
            :indexValue="index + 1"
            :meetingsLength="actionPlan?.action_plan_meetings.length"
            :actionPlanId="actionPlan?.id"
            :actionPlanStatus="actionPlan?.status"
            :meeting="meeting"
            :key="meeting"
            v-if="tabsIndex === index + 1"
            @update="updateActionPlanStatus"
            @updateAndAdd="updateStatusAndAddMeeting"
            @addMeeting="addMeeetingTab"
            @createMeeting="createActionPlanMeeting"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import ActionPlanMeeting from "@/components/newUi/PatientSurveys/ActionPlanMeeting.vue";
import Tabs from "@/components/newUi/baseComponents/Tabs.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import icon from "@/assets/icons/success.svg";
import ActionPlanComponent from "@/pages/HQ/monitoringOfPracticeFunctions/PatientSurveys/ActionPlans/CreateActionPlan.vue";

const route = useRoute();
const router = useRouter();
const store = useStore();
const activeTabIndex = ref();
const tabsIndex = ref(0);
const actionPlan = ref(null);
const qofTrue = route.params.qof;

onMounted(async () => {
  actionPlan.value = await store.dispatch(
    "actionPlans/fetchSingleActionPlan",
    route.params.actionPlanId
  );
});

const tabsData = computed(() => {
  let tabs = [{ name: "Action Plan" }];

  if (actionPlan.value?.action_plan_meetings) {
    for (
      let index = 0;
      index < actionPlan.value?.action_plan_meetings.length;
      index++
    ) {
      tabs.push({ name: `Action Plan Meeting ${index + 1}` });
    }
  }
  return tabs;
});

const switchTab = (index) => {
  tabsIndex.value = index;
};
const updateActionPlanStatus = async (payload) => {
  const response = await store.dispatch(
    "actionPlans/updateActionPlanStatus",
    payload
  );
  if (response.data.success) {
    Swal.fire({
      toast: true,
      position: "top-end",
      title: `${response.data.message}`,
      iconHtml: `<img src="${icon}" />`,
      width: "32em",
      customClass: {
        title: "popup-title",
        popup: "popup-border",
        content: "popup-text",
        icon: "popup-icon-border",
      },
      timer: 2000,
      showConfirmButton: false,
      background: `rgba(225, 249, 240, 1)`,
    });
    actionPlan.value = await store.dispatch(
      "actionPlans/fetchSingleActionPlan",
      route.params.actionPlanId
    );
    if (qofTrue === "true") {
      router.push({ name: "hq-monitoringOfPracticeFunctionsQofDashboard" });
    } else {
      router.push({ name: "hq-HqMonitoringOfPracticeFunctionsPatientSurvey" });
    }
  }
};
const updateStatusAndAddMeeting = async (payload) => {
  const response = await store.dispatch(
    "actionPlans/updateActionPlanStatus",
    payload
  );
  if (response.data.success) {
    Swal.fire({
      toast: true,
      position: "top-end",
      title: `${response.data.message}`,
      iconHtml: `<img src="${icon}" />`,
      width: "32em",
      customClass: {
        title: "popup-title",
        popup: "popup-border",
        content: "popup-text",
        icon: "popup-icon-border",
      },
      timer: 2000,
      showConfirmButton: false,
      background: `rgba(225, 249, 240, 1)`,
    });
    actionPlan.value = await store.dispatch(
      "actionPlans/fetchSingleActionPlan",
      route.params.actionPlanId
    );
    actionPlan.value?.action_plan_meetings.push({ is_active: -1 });
    activeTabIndex.value = tabsData.value.length - 1;
    switchTab(activeTabIndex.value);
  }
};
const addMeeetingTab = () => {
  actionPlan.value?.action_plan_meetings.push({ is_active: -1 });
  activeTabIndex.value = tabsData.value.length - 1;
  switchTab(activeTabIndex.value);
};
const createActionPlanMeeting = async (payload) => {
  const response = await store.dispatch(
    "actionPlans/createActionPlanMeeting",
    payload
  );
  if (response.data.success) {
    Swal.fire({
      toast: true,
      position: "top-end",
      title: `Successfully created new meeting`,
      iconHtml: `<img src="${icon}" />`,
      width: "32em",
      customClass: {
        title: "popup-title",
        popup: "popup-border",
        content: "popup-text",
        icon: "popup-icon-border",
      },
      timer: 2000,
      showConfirmButton: false,
      background: `rgba(225, 249, 240, 1)`,
    });
    actionPlan.value = await store.dispatch(
      "actionPlans/fetchSingleActionPlan",
      route.params.actionPlanId
    );
    activeTabIndex.value = tabsData.value.length - 1;
    switchTab(activeTabIndex.value);
  }
};
</script>
