<template>
  <div class="bg-white rounded-2xl p-8">
    <div class="flex flex-col gap-2">
      <label class="block text-gray-700 font-semibold text-xs" for="grid-zip">
        Add Participants<span class="text-red">*</span>
      </label>

      <div
        class="flex flex-wrap border border-gray-300 rounded"
        :class="updateMode ? 'bg-teal bg-opacity-5' : 'bg-white'"
      >
        <div
          v-for="(participant, index) in createMeeting.meeting_participants"
          :key="index"
          class="bg-gray-100 justify-center items-center rounded-md m-2 px-3 py-2 text-xs"
          :class="updateMode ? 'bg-teal' : 'bg-gray-100'"
        >
          <div class="flex justify-between items-center">
            <div
              class="font-normal"
              :class="updateMode ? 'text-white' : 'text-gray-500'"
            >
              {{ participant }}
            </div>
            <div
              v-show="!updateMode"
              class="w-4 ml-3 cursor-pointer"
              @click="removeChip(index)"
            >
              <img src="../../../assets/images/cancel.png" />
            </div>
          </div>
        </div>
        <input
          v-if="!updateMode"
          :disabled="
            updateMode || createMeeting.meeting_participants.length >= 4
          "
          type="email"
          placeholder="Please select meeting recipients"
          :id="'role'"
          class="appearance-none block w-full h-5 text-gray-700 py-3 px-4 my-3 leading-tight focus:outline-none focus:bg-white"
          v-model="participant"
          maxlength="100"
          minlength="8"
          @keyup.enter="addParticipant()"
        />
      </div>
      <small class="text-red" v-show="invalidEmail"
        >Please enter a valid email!</small
      >
      <small class="text-red" v-show="emailIsPresent"
        >Email is added already</small
      >
      <small
        class="text-orange"
        v-show="createMeeting.meeting_participants.length === 4 && !updateMode"
      >
        Maximum Participant limit reached</small
      >
      <p
        class="text-red"
        v-for="error in v$.createMeeting.meeting_participants.$errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
      <label
        class="block text-gray-700 font-semibold text-xs pt-3"
        for="grid-zip"
      >
        Date of Meeting<span class="text-red">*</span>
      </label>
      <div class="border rounded focus:border-gray-500">
        <DatePicker
          :placeholder="'Date of the meeting'"
          :isDisabled="updateMode"
          :needDisableColor="true"
          :selectedDate="createMeeting.meeting_date"
          @selected-date="setMeetingDate"
          @cleared-date="setMeetingDate"
          :class="
            v$.createMeeting.meeting_date.$error === true
              ? 'border-red'
              : 'border-gray-300'
          "
        />
      </div>
      <p
        class="text-red"
        v-for="error in v$.createMeeting.meeting_date.$errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
      <label
        v-if="newMeeting === false"
        class="block text-gray-700 font-semibold text-xs pt-3"
        for="grid-zip"
      >
        Meeting Minutes<span class="text-red">*</span>
      </label>
      <textarea
        v-if="newMeeting === false"
        type="text"
        :disabled="viewMode"
        maxlength="2000"
        name="comment"
        class="p-5 bg-white border text-darkGrey rounded shadow-sm h-36"
        v-model="updateMeeting.meeting_minutes"
        :class="
          v$.updateMeeting.meeting_minutes.$error === true
            ? 'border-red'
            : 'border-lightGrey'
        "
      ></textarea>
      <p
        class="text-red"
        v-for="error in v$.updateMeeting.meeting_minutes.$errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
      <label
        v-if="newMeeting === false"
        class="block text-gray-700 font-semibold text-xs pt-3"
        for="grid-zip"
      >
        Action Plan Status<span class="text-red">*</span>
      </label>
      <v-select
        v-if="newMeeting === false"
        :disabled="viewMode"
        placeholder="Select Status"
        class="w-full"
        label="status"
        v-model="updateMeeting.status"
        :searchable="false"
        :options="statusOptions"
      >
      </v-select>
      <p
        class="text-red"
        v-for="error in v$.updateMeeting.status.$errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
      <div
        v-if="actionPlanStatus != 'completed'"
        class="flex justify-between mt-10"
      >
        <button
          class="px-8 py-2 bg-transparent border border-teal text-teal rounded-md font-light"
          @click.prevent="$router.go(-1)"
        >
          Cancel
        </button>
        <button
          v-if="newMeeting === true"
          class="px-8 py-2 bg-teal border border-teal text-white rounded-md font-light"
          @click="createFunc"
        >
          Create
        </button>
        <button
          v-if="updateMeeting.status === 'completed' && updateMode === true"
          class="px-8 py-2 bg-teal border border-teal text-white rounded-md font-light"
          @click="updateFunc('update')"
        >
          Done
        </button>
        <button
          v-if="
            updateMeeting.status === 'in-progress' &&
            updateMode === true &&
            props.indexValue === props.meetingsLength &&
            props.indexValue < 4
          "
          class="px-8 py-2 bg-teal border border-teal text-white rounded-md font-light"
          @click="updateFunc('updateAndAdd')"
        >
          Call For a New Meeting
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, defineProps, defineEmits, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import DatePicker from "../atoms/DatePicker.vue";
import moment from "moment";

const statusOptions = ref(["in-progress", "completed"]);
const participant = ref(null);
const invalidEmail = ref(null);
const emailIsPresent = ref(false);
const createMeeting = ref({
  meeting_participants: [],
  meeting_date: null,
});
const updateMeeting = ref({
  meeting_minutes: null,
  status: null,
});
const updateMode = ref(false);
const viewMode = ref(false);
const newMeeting = ref(false);

const props = defineProps({
  actionPlanId: {
    type: String,
    required: true,
  },
  actionPlanStatus: {
    type: Array,
    required: true,
  },
  meeting: {
    type: Array,
    required: false,
  },
  indexValue: {
    type: String,
    required: false,
  },
  meetingsLength: {
    type: String,
    required: false,
  },
});

const hasEmail = (value) => {
  const emailRegex = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/;

  return emailRegex.test(value);
};

const addParticipant = () => {
  if (participant.value.length > 8 && hasEmail(participant.value)) {
    if (!createMeeting.value.meeting_participants.includes(participant.value)) {
      createMeeting.value.meeting_participants.push(participant.value);
      participant.value = null;
      invalidEmail.value = false;
      emailIsPresent.value = false;
    } else {
      emailIsPresent.value = true;
    }
  } else {
    invalidEmail.value = true;
  }
};

const removeChip = (index) => {
  createMeeting.value.meeting_participants.splice(index, 1);
};

onMounted(async () => {
  if (props.meeting) {
    if (props.meeting.is_active === 1) {
      participantsFunc(props.meeting?.meeting_participants);
      createMeeting.value.meeting_date = moment(props.meeting?.date).format(
        "MMM DD, YYYY"
      );
      updateMode.value = true;
    } else if (props.meeting.is_active === 0) {
      participantsFunc(props.meeting?.meeting_participants);
      createMeeting.value.meeting_date = moment(props.meeting?.date).format(
        "MMM DD, YYYY"
      );
      updateMeeting.value.meeting_minutes = props.meeting?.meeting_minutes;
      updateMode.value = true;
      viewMode.value = true;
    } else if (props.meeting.is_active === -1) {
      newMeeting.value = true;
    }
  }
  updateMeeting.value.status = props.actionPlanStatus;
});

const rules = computed(() => ({
  createMeeting: {
    meeting_participants: { required },
    meeting_date: { required },
  },
  updateMeeting: {
    meeting_minutes: {
      required: requiredIf(function () {
        return newMeeting.value === false;
      }),
    },
    status: {
      required: requiredIf(function () {
        return newMeeting.value === false;
      }),
    },
  },
}));

const v$ = useVuelidate(rules, { createMeeting, updateMeeting });
const emits = defineEmits([
  "update",
  "addMeeting",
  "updateAndAdd",
  "createMeeting",
]);

const updateFunc = async (value) => {
  await v$.value.$touch();
  if (v$.value.$error) {
    return;
  }
  const payload = {
    action_plan: props.actionPlanId,
    action_plan_meeting: props.meeting.id,
    status: updateMeeting.value.status,
    meeting_minutes: updateMeeting.value.meeting_minutes,
  };
  if (value === "update") {
    emits("update", payload);
  } else if (value === "updateAndAdd" && viewMode.value === true) {
    emits("addMeeting", payload);
  } else {
    emits("updateAndAdd", payload);
  }
};
const createFunc = async () => {
  await v$.value.$touch();
  if (v$.value.$error) {
    return;
  }
  const payload = {
    action_plan: props.actionPlanId,
    meeting_date: createMeeting.value.meeting_date,
    meeting_participants: createMeeting.value.meeting_participants,
  };
  emits("createMeeting", payload);
};
const participantsFunc = (participants) => {
  for (let index = 0; index < participants.length; index++) {
    createMeeting.value.meeting_participants.push(
      participants[index].participant
    );
  }
};

const setMeetingDate = (dateSelected) => {
  if (dateSelected) {
    createMeeting.value.meeting_date = dateSelected;
  } else {
    createMeeting.value = null;
  }
};
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
</style>
